import type React from 'react';
import { Controller, type FieldValues, type Path, type UseFormReturn } from 'react-hook-form';

import { get } from '@/renderer/utils/get';
import { Label } from '../react-aria/field';
import TextInput from './TextInput';

// import type { FieldError, FieldValues, Path, UseFormReturn } from 'react-hook-form';

export default FormControl;

export type FormErrors = { formErrors?: never };
export type WithFormErrors<X> = X & FormErrors;
// export type UseFormReturnWithFormErrors<X> = UseFormReturn<WithFormErrors<X>>;

// biome-ignore lint/complexity/noBannedTypes: Gotta fix {} at some point
type RequiredKeys<T> = { [K in keyof T]-?: {} extends Pick<T, K> ? never : K }[keyof T];

type WrapOwnProps<
  E1 extends React.ElementType = React.ElementType,
  E2 extends React.ElementType = React.ElementType,
  F extends FieldValues = FieldValues,
  Name extends Path<F> = Path<F>,
> = {
  as?: E2;
  children?: React.ReactNode;
  forgotPassword?: boolean;
  form?: UseFormReturn<F>;
  formBehavior?: 'classic' | 'react-aria';
  label?: string | React.ReactNode;
  id?: string;
  inline?: boolean;
  inside?: boolean;
  name: Name;
  register?: Parameters<UseFormReturn<F>['register']>[1];
  wrap?: E1;
};

interface PropsComponentProps<E extends React.ElementType> {
  props?: React.ComponentProps<E>;
}

type WrapProps<
  E1 extends React.ElementType,
  E2 extends React.ElementType,
  F extends FieldValues,
  Name extends Path<F>,
> = RequiredKeys<React.ComponentProps<E2>> extends never
  ? WrapOwnProps<E1, E2, F, Name> &
      Omit<React.ComponentProps<E1>, keyof WrapOwnProps<E1, E2, F, Name>> &
      PropsComponentProps<E2>
  : WrapOwnProps<E1, E2, F, Name> &
      Omit<React.ComponentProps<E1>, keyof WrapOwnProps<E1, E2, F, Name>> &
      PropsComponentProps<E2>;

const DEFAULT_AS = 'div';
const DEFAULT_WRAP = TextInput;
const VALIDATION_PROPS = ['required', 'min', 'max', 'minLength', 'maxLength', 'pattern'] as const;

function FormControl<
  E1 extends React.ElementType = typeof DEFAULT_WRAP,
  E2 extends React.ElementType = typeof DEFAULT_AS,
  F extends FieldValues = FieldValues,
  Name extends Path<F> = Path<F>,
>({
  as,
  children,
  forgotPassword: _forgotPassword,
  form,
  id,
  inline,
  inside,
  label,
  name,
  props,
  register,
  wrap,
  formBehavior = 'classic',
  ...rest
}: WrapProps<E1, E2, F, Name>) {
  const As = as || DEFAULT_AS;
  const Wrap = wrap || DEFAULT_WRAP;

  let validationProps:
    | {
        required?: boolean;
        min?: number;
        max?: number;
        minLength?: number;
        maxLength?: number;
        pattern?: RegExp;
      }
    | undefined;

  // Hoist some validation props from the HTML element into register(x, { ... })
  // This allows us to do both HTML form validation AND react-hook-form validation
  for (const key of VALIDATION_PROPS) {
    if (key in rest) {
      const k = key satisfies (typeof VALIDATION_PROPS)[number];
      const value = register?.[k]
        ? // @TODO biome-ignore lint/suspicious/noExplicitAny:
          { value: (rest as any)[k], ...(rest as any)[k] }
        : // @TODO biome-ignore lint/suspicious/noExplicitAny:
          (rest as any)[k];

      if (validationProps) {
        validationProps[k] = value;
      } else {
        validationProps = { [k]: value };
      }

      if (k === 'pattern') {
        validationProps[k] = new RegExp(validationProps[k] as unknown as string);
      }
    }
  }

  // Omit is to fix some weird validation from register. it doesn't allow mixing pattern with valueAsDate/valueAsNumber
  const registerObj = (
    register && validationProps ? { ...register, ...validationProps } : validationProps || register
  ) as undefined | Omit<Parameters<UseFormReturn<F>['register']>[1], 'pattern'>;
  const required = register?.required || register?.minLength || register?.min || register?.pattern;

  const thisErr = get(form?.formState?.errors, name);

  const genId = id ? id : name;
  const wrapProps: React.ComponentProps<typeof Wrap> = {
    id: genId,
    name: name,
    error: typeof Wrap === 'string' ? undefined : !!thisErr,
    onInvalid:
      validationProps && form
        ? (event) => {
            // @TODO biome-ignore lint/suspicious/noExplicitAny:
            const message = (event?.target as any)?.validationMessage as string;

            if (message) {
              form?.setError(name, { message });
              event.preventDefault();
            }
          }
        : undefined,
    onInput:
      validationProps && form
        ? (event) => {
            // @TODO biome-ignore lint/suspicious/noExplicitAny:
            if ((event?.target as any)?.validity?.valid && thisErr) {
              form.clearErrors(name);
            }
          }
        : undefined,
  };

  if (formBehavior === 'react-aria') {
    return (
      <Controller
        control={form?.control}
        defaultValue={
          props?.defaultValue !== undefined ? props.defaultValue : wrapProps?.defaultValue
        }
        name={name}
        render={({ field: { ref, ...field } }) => (
          <As {...props} {...field}>
            <Label htmlFor={genId}>{label}</Label>
            <Wrap
              {...wrapProps}
              ref={ref}
              // biome-ignore lint/correctness/noChildrenProp: Best way to do this with a wrap
              children={children}
              {...rest}
            />
          </As>
        )}
      />
    );
  }

  const field = (!wrap && children) || (
    <Wrap
      {...(!!form?.register && form.register(name, registerObj))}
      {...wrapProps}
      // biome-ignore lint/correctness/noChildrenProp: Best way to do this with a wrap
      children={children}
      {...rest}
    />
  );

  return (
    <As {...props}>
      {inline ? (
        <div className="relative flex items-start">
          {field}
          <label
            htmlFor={genId}
            className="ml-3 text-left text-xs text-zinc-700 dark:text-zinc-400 empty:hidden"
          >
            {label}
            {!!required && '*'}
          </label>
        </div>
      ) : (
        <>
          <label
            htmlFor={genId}
            className="mb-2 block cursor-pointer justify-start text-base font-medium leading-6 text-zinc-800 sm:text-sm dark:text-white empty:hidden"
          >
            {!!inside && field}
            {inside ? (
              <span className="ml-2 text-xs leading-snug text-zinc-700 dark:text-zinc-400 empty:hidden">
                {label}
                {!!required && '*'}
              </span>
            ) : (
              <>
                {label}
                {!!required && '*'}
              </>
            )}
          </label>

          {!inside && field}
        </>
      )}

      <label className="mt-1 text-xs text-red-500">{thisErr?.message?.toString()}</label>
    </As>
  );
}
