import type { DetailedHTMLProps, InputHTMLAttributes, Ref } from 'react';
import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

function TextInput(
  {
    error,
    className,
    ...props
  }: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: boolean;
    className?: string;
  },
  ref: Ref<HTMLInputElement>,
) {
  return (
    <input
      {...props}
      ref={ref}
      className={twMerge(
        'block h-auto w-full rounded-sm border-0 px-3 py-2 text-base leading-6 text-zinc-900 shadow-xs ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:outline-hidden focus:ring-2 focus:ring-inset focus:ring-emerald-500 focus-visible:outline-hidden dark:bg-white/5 dark:text-zinc-200 dark:ring-white/10 dark:focus:ring-emerald-400 sm:text-sm',
        !!error && 'ring-red-500',
        className,
      )}
    />
  );
}

export default forwardRef(TextInput);
